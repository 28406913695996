/* Mixins */
.parking-index-component {
  min-width: 1236px !important;
}
.parking-index-component .page-body-margin {
  margin: 16px;
}
.parking-index-component .chart-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.parking-index-component .chart-wrapper .jtl-card-component {
  width: calc(50% - 10px);
  height: 445px;
}
.parking-index-component .chart-wrapper .jtl-card-component .chart-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.parking-index-component .chart-wrapper .jtl-card-component .chart-wrapper {
  height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.parking-index-component .chart-wrapper .jtl-card-component .chart-wrapper .gauge-chart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.parking-index-component .chart-wrapper .jtl-card-component .chart-wrapper .gauge-chart .gauge-chart-text-wrapper {
  width: 160px;
  margin: 24px auto 0 auto;
}
.parking-index-component .chart-wrapper .jtl-card-component .chart-wrapper .gauge-chart .gauge-chart-text-wrapper .gauge-title {
  padding-right: 8px;
  border-right: solid 1px #EDEEF0;
  font-size: 14px;
  font-weight: bold;
}
.parking-index-component .chart-wrapper .jtl-card-component .chart-wrapper .gauge-chart .gauge-chart-text-wrapper .gauge-count {
  font-size: 14px;
  font-weight: bold;
}
.parking-index-component .chart-wrapper .jtl-card-component .chart-wrapper .gauge-chart .gauge-chart-text-wrapper .gauge-desc {
  font-size: 12px;
}
.parking-index-component .chart-wrapper .jtl-card-component .chart-wrapper .chart-withtable-wrapper {
  width: 45%;
}
.parking-index-component .chart-wrapper .jtl-card-component .chart-wrapper .chart-notable-wrapper {
  width: 100%;
}
.parking-index-component .chart-wrapper .jtl-card-component .chart-wrapper .chart-table {
  width: 50%;
}
.parking-index-component .chart-wrapper .jtl-card-component .chart-no-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 335px;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
.parking-index-component .chart-wrapper .jtl-card-component:nth-child(even) {
  margin: 0px 0px 16px 10px;
}
.parking-index-component .chart-wrapper .jtl-card-component:nth-child(odd) {
  margin: 0px 10px 16px 0px;
}